// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #efefef;
  --ion-toolbar-background: #fff;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);

  /** primary **/
  --ion-color-primary: #222428;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2a2a2a;
  --ion-color-primary-tint: #363636;

  /** secondary **/
  --ion-color-secondary: #3880ff;
  --ion-color-secondary-rgb: 56, 128, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3171e0;
  --ion-color-secondary-tint: #4c8dff;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #8ec252;
  --ion-color-success-rgb: 142, 194, 82;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #7dab48;
  --ion-color-success-tint: #99c863;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #222428;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #222428;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-zebra-item-background: #f0f1f4;
  --ion-link-color: #3880ff;
  --menu-item-selected-background-color: #8ec252;
  --menu-item-selected-color: white;

  --ion-color-step-950: #1e1e1e;
  --ion-color-step-900: #2a2a2a;
  --ion-color-step-850: #363636;
  --ion-color-step-800: #414141;
  --ion-color-step-750: #4d4d4d;
  --ion-color-step-700: #595959;
  --ion-color-step-650: #656565;
  --ion-color-step-600: #717171;
  --ion-color-step-550: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-450: #949494;
  --ion-color-step-400: #a0a0a0;
  --ion-color-step-350: #acacac;
  --ion-color-step-300: #b8b8b8;
  --ion-color-step-250: #c4c4c4;
  --ion-color-step-200: #d0d0d0;
  --ion-color-step-150: #dbdbdb;
  --ion-color-step-100: #e7e7e7;
  --ion-color-step-50: #f3f3f3;
  --ion-item-background: #fff;
  --toolbar-color: rgba(50, 50, 50, 0.1);
  --login-item-background: #e7e7e7;
  --ion-border-color: #e7e7e7;
  --ion-border-color-rgb: 231, 231, 231;
  --reveal-gradient-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-top: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --reveal-gradient-bottom: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  --ion-color-gold: #ffd700;
  --ion-color-gold-rgb: 255, 215, 0;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #e0bd00;
  --ion-color-gold-tint: #ffdb1a;
}

ion-toast {
  --button-color: #3880ff;
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.dark {
  --ion-background-color: #222428;
  --ion-color-success-contrast: #222428;
  --ion-toolbar-background: #222428;
  --menu-item-selected-color: black;
  --toolbar-color: rgba(0, 0, 0, 0.18);
  --reveal-gradient-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    var(--ion-background-color) 100%
  );
  --reveal-gradient-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    var(--ion-background-color) 100%
  );

  --ion-text-color: #f4f5f8;
  --ion-text-color-rgb: 244, 245, 248;

  --ion-border-color: #313131;
  --ion-border-color-rgb: 49, 49, 49;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
  --login-item-background: #1a1b1e;
  --ion-zebra-item-background: #1f2023;
  --color-toolbar-background: black;
  --color-toolbar-text: #f4f5f8;

  --ion-color-primary: #f4f5f8;
  --ion-color-primary-rgb: 244, 245, 248;
  --ion-color-primary-contrast: #222428;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e7e7e7;
  --ion-color-primary-tint: #fff;

  ion-toast {
    --button-color: #222428;
  }
}
