.chat-footer {
  --background: transparent;
  align-items: stretch;
  flex: 1 1 auto;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --inner-padding-end: 0;
  --background-focused: transparent;

  .chat-tool {
    --padding-start: 3px;
    --padding-end: 3px;
  }

  .send-message-button {
    background-color: var(--ion-color-secondary);
    border-radius: 100%;
    color: white;
    height: 26px;
    width: 0;
    transform: translate(100%, 0);
    transition: all ease-in-out 0.1s;

    &.visible {
      width: 26px;
      transform: none;
    }
  }

  &.internal {
    .chat-message-box {
      background-color: rgba(var(--ion-color-warning-rgb), 0.1);
    }

    .send-message-button {
      background-color: var(--ion-color-warning);
      color: black;
    }
  }
  &.optout {
    .chat-message-box {
      background-color: rgba(var(--ion-color-danger-rgb), 0.1);
    }

    .send-message-button {
      background-color: var(--ion-color-danger);
      color: black;
    }
  }

  .chat-message-box {
    align-items: center;
    background-color: var(--toolbar-color);
    border-radius: 6px;
    display: inline-flex;
    margin: 5px;
    min-height: 35px;
    padding: 0 7px;
    flex: 1 1 auto;
    width: auto;

    .chat-message-box-input {
      margin: 0;
      height: 40px;
      transition: height ease-in-out 0.1s;
      --padding-bottom: 10px;
      margin-right: 10px;
      > .native-textarea {
        line-height: 1.2;
        height: 100%;
      }
    }
  }

  .send-message {
    margin: auto 5px;
  }

  .paused {
    color: var(--ion-color-warning);
  }
}

.waiting-lead {
  .backdrop-no-scroll & {
    display: none;
  }

  background: var(--ion-color-secondary);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: var(--ion-color-secondary-contrast);
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 10px;
  height: 30px;
  width: 150px;
  text-align: center;
}
