.t-datepicker-container {
  align-items: center;
  border-bottom: solid 1px var(--ion-border-color);
  display: flex;
  padding: 15px;

  .custom-date-input {
    padding: 0;
    margin: 0;
  }

  &.inline {
    border-bottom: none;

    .t-datepicker-label-control {
      width: 100%;
      text-align: center;
    }
  }

  .t-datepicker-label-control {
    align-items: baseline;
    flex: 0 0 auto;
  }

  .react-datepicker-wrapper {
    flex: 0 0 auto;
    text-align: right;
  }

  .t-datepicker {
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: var(--ion-text-color);
    outline: none;
  }

  ion-icon {
    font-size: 22px;
  }

  .spacer {
    flex: 1 1 auto;
  }

  ion-label {
    flex: 0 0 auto;
    padding: 0;
    margin: 0 20px 0 0;
  }
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  background-color: var(--ion-color-success) !important;
  outline: none;
}

@media screen and (max-width: 640px) {
  .react-datepicker__time-box {
    width: 100% !important;
  }

  .react-datepicker__portal {
    height: 100%;
  }

  .react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
    right: 20px;
  }

  .react-datepicker__month-container {
    float: none;
    flex: 0 0 auto;
    width: 100%;
  }

  .react-datepicker__time-list {
    max-height: 20vh;
  }

  .react-datepicker__time-container {
    float: none;
    width: 100%;
  }
}

.react-datepicker-popper {
  z-index: 10;
}
