.facebook-campaign-overview {
  border-bottom: solid 1px var(--ion-border-color);

  .facebook-campaign-overview-kpis {
    color: var(--ion-text-color);
    height: 280px;
    position: relative;
    margin: auto;
    text-align: center;

    ion-select {
      background-color: var(--ion-border-color);
      border-radius: 50px;
      font-weight: 500;
      padding: 5px 10px;
      margin-bottom: 3px;
    }

    .date-range {
      color: var(--ion-color-step-350);
      font-size: 12px;
      margin-top: 5px;
    }

    .header-stats {
      border-bottom: solid 1px var(--ion-border-color);

      label {
        color: var(--ion-color-step-550);
        font-size: 15px;
        margin-right: 5px;
      }

      strong {
        font-size: 18px;
      }
    }

    ion-row:nth-child(2) {
      .kpi-metric:first-child {
        border-top-left-radius: 8px;
      }

      .kpi-metric:last-child {
        border-top-right-radius: 8px;
      }
    }

    ion-row:last-child {
      .kpi-metric:first-child {
        border-bottom-left-radius: 8px;
      }

      .kpi-metric:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    .kpi-metric {
      background-color: var(--ion-item-background);
      &:not(:last-child) {
        border-right: solid 1px var(--ion-border-color);
      }

      &.profit {
        strong {
          color: var(--ion-color-success);
        }
      }

      h3 {
        font-size: 24px;
        margin: 0 0 5px;
      }

      span {
        color: var(--ion-color-step-550);
        font-size: 11px;
        text-transform: uppercase;
      }

      .sublabel {
        color: var(--ion-color-step-350);
        font-size: 10px;
      }

      padding: 15px 0;
    }
  }
}
