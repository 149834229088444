.quick-replies-modal {
  .t-modal-toolbar {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    ion-toolbar {
      padding: 0 10px;
    }
  }

  .category-select {
    width: 100%;
  }
}
