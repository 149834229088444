.popover-selector {
  --height: 300px;
  --width: 80vw;
  --max-height: 600px;
  --max-width: 600px;

  .dark & {
    --ion-item-background: var(--ion-color-step-100);
  }

  .popover-content {
    background: var(--ion-item-background, #ffffff);
    color: var(--ion-color-step-850);
  }
}
