.emoji-picker-container {
  position: absolute !important;
  height: 320px;
  width: 280px;
  bottom: calc(50px + var(--ion-safe-area-bottom));
  right: 20px;
  z-index: 1000;
}

.emoji-picker-react {
  border-color: var(--ion-border-color) !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7) !important;
  background-color: var(--ion-item-background, #fff) !important;
  color: var(--ion-text-color) !important;

  input {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: var(--ion-border-color) !important;
    margin: 10px 0;
  }

  .emoji-group::before {
    background: var(--ion-item-background, #fff) !important;
  }

  .skin-tones-list {
    top: 25px !important;
  }

  .emoji-categories,
  .emoji-search,
  .skin-tones-list {
    display: none !important;
  }

  .has-skin-variation::before {
    background: var(--ion-text-color) !important;
  }

  .dark & .variation-list {
    border-color: var(--ion-border-color) !important;
    background: var(--ion-item-background) !important;
  }
}

.emoji-picker-button {
  font-size: 22px;
}
