.chat-tools {
  --background: var(--background);
  --padding-start: 0;
  --padding-end: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height ease-in-out 0.25s;

  ion-col {
    text-align: center;
  }

  .chat-tool {
    font-size: 18px;
    height: auto;
    margin: 0;
    position: relative;
    --padding-top: 10px;
    --padding-bottom: 35px;

    &::after {
      font-size: 9px;
      color: var(--text-color);
      content: attr(title);
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
    }
  }

  &.visible {
    border-bottom: solid 1px var(--ion-border-color);
    height: 100px;

    @media screen and (max-width: 800px) {
      height: 200px;
    }

    pointer-events: unset;
  }

  .archived {
    color: var(--ion-color-danger);
  }
}
